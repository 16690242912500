<template>
  <div>
    <vue-element-loading
    :active="appLoading"
    spinner="bar-fade-scale"
    color="#4480C5"
    size="60"
    is-full-screen
  />
    <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center my-6>
      <v-flex pt-5 xs11 sm11 md11 lg11 xl11>
        <!-- table Section -->

        <v-layout wrap justify-start>
          <v-flex xs12 sm12 md12 lg12>
            <v-card class="pa-3" elevation="3">
            


              <v-layout wrap justify-space-between align-center>
                <!-- Left Side: Avatar, Name, and Title -->
                <v-flex xs12 sm4 md6 lg6 px-lg-2 px-md-2 px-sm-2 px-2>
                  <v-layout align-center>
                    <v-avatar size="55" color="primary">
                      <v-icon large color="white">mdi-account</v-icon>
                    </v-avatar>
              
                    <!-- Name and Title -->
                    <div class="ml-3">
                      <h3 class="holymasshdes" style="font-size: 18px">
                        {{ managerslist.name }}
                      </h3>
                      <small class="text-des">Parish Manager</small>
                    </div>
                  </v-layout>
                </v-flex>
              
                <!-- Right Side: Phone and Email -->
                <v-flex xs12 sm8 md6 lg6 px-lg-4 px-md-4 px-sm-4 px-4>
                  <v-layout wrap justify-end>
                    <div class="my-2 ml-4">
                      <v-icon color="primary" size="20">mdi-phone</v-icon> :
                      <span class="ml-2 live-des">{{ managerslist.phoneNumber }}</span>
                    </div>
                    <div class="my-2 ml-3 live-des">
                      <v-icon color="primary" size="20">mdi-email</v-icon> :
                      <span class="ml-2">{{ managerslist.email }}</span>
                    </div>
                  </v-layout>
                </v-flex>
              </v-layout>
              

              

              <v-card-text>
                <!-- Display manager details -->

                <v-divider class="my-3"></v-divider>

 <v-flex text-end pb-3>
      <v-btn outlined color="blue darken-5"   @click="adddialog = true" small
        ><v-icon> mdi-plus </v-icon>Assign New Church</v-btn
      >

      <v-dialog v-model="adddialog" max-width="700px">
        <v-card rounded="lg">
          <v-card-title>
            <span class="editbox">ASSIGN NEW CHURCH</span>
            <v-spacer></v-spacer>
            <v-btn color="red" icon @click="adddialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-form>
              <v-layout wrap justify-center px-2>


                <v-flex xs12 pr-2>
                  <span class="label">Select Church</span>
                  <v-autocomplete
                    class="pt-2"
                    v-model="churchId"
                    :items="churchArray"
                    dense
                    outlined
                    multiple
                    clearable
                    :search-input.sync="search1"
                    item-text="name"
                    item-value="_id"
                  ></v-autocomplete>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="py-4 justify-end headline grey lighten-2">
            <v-btn outlined color="grey" text @click="adddialog = false"
              ><span style="color: black">Cancel</span>
            </v-btn>
            <v-btn color="primary" @click="assignChurch()"
              >Save Changes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-flex>
                <!-- Data Table for churches -->
                 <v-template v-if="managerschurch.length>0">

                 
                <v-data-table
                  :headers="headers"
                  :items="managerschurch"
                  item-key="name"
                  class="elevation-1 text--black"
                  id="virtual-scroll-table"
                  hide-default-footer
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item._id">
                        <td>{{ item.name }}</td>
                        <td>
                          {{ item.address.city }}, {{ item.address.state }},
                          {{ item.address.country }}
                        </td>
                        <td >{{ item.email }}</td>
                        <td>
                          <v-icon
                            small
                            color="red"
                            @click.stop="openDeleteDialog(item)"
                          >
                            mdi-delete
                          </v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </template>

                  
                </v-data-table>
              </v-template>
              <v-template v-else>
                <v-flex xs12 text-center py-5 style="
                font-family: intersemibold;
                color: grey;
                font-size: 13px;
              ">
              No Assigned Churches Found
            </v-flex>
              </v-template>
              </v-card-text>

              <!-- <v-card-actions>
                  <v-btn block color="primary" @click="viewProfile">
                    View Full Profile
                  </v-btn>
                </v-card-actions> -->
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog v-model="deletedialog" max-width="600">
      <v-card class="dialog-card">
        <v-card-title class="grey lighten-2 d-flex justify-center">
          <v-icon color="red" size="32">mdi-alert</v-icon>
          <span class="ml-2">Confirm Deletion</span>
        </v-card-title>
        <v-card-text class="py-5 text-center text-des">
          <div class="body-1">
            Are you sure you want to delete this Assigned Church?
            <br />
            This action <strong>cannot be undone</strong>.
          </div>
          <v-divider class="my-3"></v-divider>
          <v-row class="pt-2" no-gutters>
            <v-col>
              <img
                src="@/assets/images/countryb.png"
                width="40px"
                height="40px"
                class="dialog-icon"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pa-2">
          <v-btn
            style="font-family: interregular; font-size: 13px; color: white"
            color="#cf3a45"
            @click="deletedialog = false"
            >Cancel</v-btn
          >
          <v-btn
            style="font-family: interregular; font-size: 13px; color: white"
            color="blue darken-1"
            @click="confirmDelete"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
import axios from "axios";
//import { search } from 'core-js/fn/symbol';
export default {
  data() {
    return {
      timeout: 5000,
      search1: "",
      churchId: [],
      churchArray: [],
      itemToDelete: null,
      deletedialog: false,
      adddialog: false,
      appLoading: false,
      msg: "",
     
      showSnackBar: false,
      ServerError: false,
      managerslist: {},
      managerschurch: [],
      headers: [
        { text: "Church Name", value: "name", width: "200px" },
        { text: "Address", value: "address", width: "200px" },
       // { text: "Address", value: "address", width: "200px" },
        { text: "Email", value: "email", width: "100px" },
        { text: "Actions", value: "_id", width: "100px" },
      ],
    };
  },
  mounted() {
    this.getData();
   
  },
  watch: {
   
    search1(val) {
      this.loading = true;
      setTimeout(() => {
        this.getChurch(val);
        this.loading = false;
      }, 500);
    },
    
  },
  methods: {
     getChurch() {
      this.appLoading = true;
      //   const currentTime = new Date().toISOString(); // Get current time in ISO format
      axios({
        url: "/get/unassigned/church/list",

        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          keyword: this.search1,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.churchArray = response.data.data;
          // this.pages = response.data.pages;
          // this.totalData = response.data.count;
          //this.pages = Math.ceil(response.data.totalLength / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
//     validateInput() {
//    if (!this.churchIds || this.churchIds.length === 0) {
//     this.msg = "Please select a church";
//     this.showSnackBar = true;
//     return;
//   } 
//   else {
//     this.assignChurch();
//   }
// },

    assignChurch() {
      this.appLoading = true;

      // const selectedUserId = this.user; // Get the selected user's _id from v-autocomplete
      const selectedChurchIds = this.churchId; // Get the selected churches' _ids from v-autocomplete

      // Prepare the payload to be sent to the backend
      const payload = {
        // id: selectedUserId,
        id:this.$route.query.id,
        churchId: selectedChurchIds,
      };

      axios({
        url: "/add/parish/manager/parish",
        method: "POST",
        data: payload,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.adddialog = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
            this.user = null; // Reset user selection
            this.churchId = []; // Reset church selection
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getData() {
      const data = {
        id: this.$route.query.id,
      };
      this.appLoading = true;
      axios({
        url: "/parish/manager/profile/view",
        method: "post",
        // params: {
        //   // role: this.role, // Pass the role parameter here
        //   // keyword: this.search,
        //   id:this.$route.query.id,
        // },
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.managerslist = response.data.data;
          this.managerschurch = response.data.managedChurches;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    openDeleteDialog(item) {
      this.itemToDelete = item;
      this.deletedialog = true;
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deleteItem(this.itemToDelete);
      }
      this.deletedialog = false;
    },
    deleteItem(r) {
      const data = {
        id: this.$route.query.id,
        churchId: r._id,
      };

      axios({
        url: "/remove/parish/manager/parish",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    //   getChurch() {
    //     this.appLoading = true;
    //     //   const currentTime = new Date().toISOString(); // Get current time in ISO format
    //     axios({
    //       url: "/get/church/list",

    //       method: "GET",
    //       headers: {
    //         token: localStorage.getItem("token"),
    //       },
    //       params: {
    //         keyword: this.search1,
    //       },
    //     })
    //       .then((response) => {
    //         this.appLoading = false;
    //         this.churchArray = response.data.data;
    //         // this.pages = response.data.pages;
    //         // this.totalData = response.data.count;
    //         //this.pages = Math.ceil(response.data.totalLength / this.limit);
    //       })
    //       .catch((err) => {
    //         this.appLoading = false;
    //         this.ServerError = true;
    //         console.log(err);
    //       });
    //   },

    //   getParishManagers() {
    //     this.appLoading = true;
    //     axios({
    //       url: "/parish/manager/list",
    //       method: "GET",
    //       // params: {
    //       //   role: this.role, // Pass the role parameter here
    //       // },
    //       headers: {
    //         token: localStorage.getItem("token"),
    //       },
    //     })
    //       .then((response) => {
    //         this.appLoading = false;
    //         this.managerslist = response.data.data;
    //       })
    //       .catch((err) => {
    //         this.appLoading = false;
    //         this.ServerError = true;
    //         console.log(err);
    //       });
    //   },

    // itemadd() {
    //     var data = {};
    //     data["name"] = this.name;

    //     axios({
    //         url: "/add/new/country",
    //         method: "POST",
    //         data: data,
    //         headers: {
    //             token: localStorage.getItem("token"),
    //         },
    //     })
    //         .then((response) => {
    //             this.appLoading = false;
    //             if (response.data.status) {
    //                 this.msg = "Added Sucessfully";
    //                 this.showsnackbar = true;
    //                 this.dialog = false;
    //                 this.name = null;

    //                 this.getData();
    //             } else {
    //                 this.msg = response.data.msg;
    //                 this.showsnackbar = true;
    //             }
    //         })
    //         .catch((err) => {
    //             this.appLoading = false;
    //             this.ServerError = true;
    //             console.log(err);
    //         });
    // },
    // getData() {
    //     this.appLoading = true;
    //     axios({
    //         url: "/get/country/list",

    //         method: "GET",
    //         headers: {
    //             token: localStorage.getItem("token"),
    //         },
    //         params: {
    //             //  count: this.count,
    //             // page: this.currentPage,

    //         },
    //     })
    //         .then((response) => {
    //             this.appLoading = false;
    //             this.countrylist = response.data.data;
    //             // this.pages = response.data.pages;
    //             // this.totalData = response.data.count;
    //         })
    //         .catch((err) => {
    //             this.appLoading = false;
    //             this.ServerError = true;
    //             console.log(err);
    //         });
    // },

    // editSlider(item) {
    //     this.editingitem = item;
    //     this.editdialog = true;

    //   // },
    //   assignUser() {
    //     this.appLoading = true;

    //     const selectedUserId = this.user; // Get the selected user's _id from v-autocomplete
    //     const selectedChurchIds = this.churchIds; // Get the selected churches' _ids from v-autocomplete

    //     // Prepare the payload to be sent to the backend
    //     const payload = {
    //       id: selectedUserId,
    //       churchIds: selectedChurchIds,
    //     };

    //     axios({
    //       url: "/assign/parish/manager",
    //       method: "POST",
    //       data: payload,
    //       headers: {
    //         token: localStorage.getItem("token"),
    //       },
    //     })
    //       .then((response) => {
    //         this.appLoading = false;
    //         if (response.data.status) {
    //           this.editdialog = false;
    //           this.getParishManagers();
    //           this.user = null; // Reset user selection
    //           this.churchIds = []; // Reset church selection
    //         } else {
    //           this.msg = response.data.msg;
    //           this.showsnackbar = true;
    //         }
    //       })
    //       .catch((err) => {
    //         this.appLoading = false;
    //         this.ServerError = true;
    //         console.log(err);
    //       });
    //   },

    //   openDeleteDialog(item) {
    //     this.itemToDelete = item;
    //     this.deletedialog = true;
    //   },
    //   confirmDelete() {
    //     if (this.itemToDelete) {
    //       this.deleteItem(this.itemToDelete);
    //     }
    //     this.deletedialog = false;
    //   },
    //   deleteItem(r) {
    //     var data = {};
    //     data["id"] = r._id;
    //     axios({
    //       url: "/delete/country",
    //       method: "POST",
    //       data: data,
    //       headers: {
    //         token: localStorage.getItem("token"),
    //       },
    //     })
    //       .then((response) => {
    //         this.delete = false;
    //         this.appLoading = false;
    //         if (response.data.status) {
    //           this.msg = "Delete Sucessfully";
    //           this.showsnackbar = true;
    //           this.getData();
    //         } else {
    //           this.msg = response.data.msg;
    //           this.showsnackbar = true;
    //         }
    //       })
    //       .catch((err) => {
    //         this.appLoading = false;
    //         this.ServerError = true;
    //         console.log(err);
    //       });
    //   },
  },
};
</script>
  
  